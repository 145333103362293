import { memo } from 'react';
import React from 'react';

const GroupIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M12 13.172L16.95 8.222L18.364 9.636L12 16L5.636 9.636L7.05 8.222L12 13.172Z' fill='#09121F' />
  </svg>
);
const Memo = memo(GroupIcon);
export { Memo as GroupIcon };

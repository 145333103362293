import { memo, SVGProps } from 'react';
import React from 'react';

const Frame277Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 942 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_d_103_18)'>
      <circle cx={10} cy={10} r={10} fill='#0B4E8E' />
      <circle cx={10} cy={10} r={10} stroke='white' strokeWidth={3} />
    </g>
    <rect x={24} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={109} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={194} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={279} y={4} width={81} height={12} fill='#F5F5F5' />
    <g filter='url(#filter1_d_103_18)'>
      <circle cx={374} cy={10} r={10} fill='#F5F5F5' />
      <circle cx={374} cy={10} r={10} stroke='white' strokeWidth={3} />
    </g>
    <rect x={388} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={473} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={558} y={4} width={81} height={12} fill='#F5F5F5' />
    <g filter='url(#filter2_d_103_18)'>
      <circle cx={653} cy={10} r={10} fill='#F5F5F5' />
      <circle cx={653} cy={10} r={10} stroke='white' strokeWidth={3} />
    </g>
    <rect x={667} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={752} y={4} width={81} height={12} fill='#F5F5F5' />
    <rect x={837} y={4} width={81} height={12} fill='#F5F5F5' />
    <g filter='url(#filter3_d_103_18)'>
      <circle cx={932} cy={10} r={10} fill='#F5F5F5' />
      <circle cx={932} cy={10} r={10} stroke='white' strokeWidth={3} />
    </g>
    <defs>
      <filter
        id='filter0_d_103_18'
        x={-5.5}
        y={-4.5}
        width={31}
        height={31}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_103_18' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_103_18' result='shape' />
      </filter>
      <filter
        id='filter1_d_103_18'
        x={358.5}
        y={-4.5}
        width={31}
        height={31}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_103_18' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_103_18' result='shape' />
      </filter>
      <filter
        id='filter2_d_103_18'
        x={637.5}
        y={-4.5}
        width={31}
        height={31}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_103_18' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_103_18' result='shape' />
      </filter>
      <filter
        id='filter3_d_103_18'
        x={916.5}
        y={-4.5}
        width={31}
        height={31}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_103_18' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_103_18' result='shape' />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(Frame277Icon);
export { Memo as Frame277Icon };

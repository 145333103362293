import './Footer.css';
import discord from '../../img/icbaselinediscord.svg';
import twitter from '../../img/mditwitter.svg';

export const Footer = () => {
    return (
      <div className="footer">
        <div className='footer__inner'>
            <div className='copyright'>
                2023 FAIR Protocol
            </div>
            <div className="footer__nav">
                <a href="#" className='footer__nav_item'>
                    About us
                </a>
                <a href="#" className='footer__nav_item'>
                    Privacy policy
                </a>
                <a href="https://fine-vegetarian-bf4.notion.site/FAIR-Protocol-c7ab588a70bb4029b059b0f8d18d01c8" className='footer__nav_item'>
                    FAIR Protocol
                </a>
            </div>
            <div className="footer__socials">
                <img
                className="assets-icon"
                alt=""
                src={discord}
                />
                <img className="assets-icon" alt="" src={twitter} />
            </div>
        </div>
      </div>
    );
  }
  
  
import { memo, SVGProps } from 'react';
import React from 'react';

const Walletconnect1Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_103_48)'>
      <path
        d='M10.2424 15.6686C18.3938 7.68593 31.607 7.68593 39.7689 15.6686L40.7496 16.6282C41.1609 17.029 41.1609 17.6722 40.7496 18.0729L37.3963 21.363C37.1959 21.5634 36.8585 21.5634 36.6581 21.363L35.3083 20.0449C29.6245 14.477 20.3974 14.477 14.7136 20.0449L13.2689 21.4579C13.0685 21.6583 12.7311 21.6583 12.5307 21.4579L9.1668 18.1678C8.75554 17.7671 8.75554 17.1239 9.1668 16.7231L10.2424 15.6686ZM46.7077 22.4597L49.6919 25.3807C50.1032 25.7815 50.1032 26.4247 49.6919 26.8254L36.2257 40.0175C35.8145 40.4182 35.1607 40.4182 34.7494 40.0175L25.1849 30.6533C25.0795 30.5584 24.9213 30.5584 24.8159 30.6533L15.2619 40.0175C14.8507 40.4182 14.1969 40.4182 13.7856 40.0175L0.308843 26.836C-0.102419 26.4353 -0.102419 25.792 0.308843 25.3913L3.29313 22.4703C3.70439 22.0696 4.35819 22.0696 4.76946 22.4703L14.3339 31.8344C14.4394 31.9293 14.5976 31.9293 14.703 31.8344L24.257 22.4703C24.6682 22.0696 25.322 22.0696 25.7333 22.4703L35.2978 31.8344C35.4032 31.9293 35.5614 31.9293 35.6669 31.8344L45.2313 22.4703C45.632 22.059 46.2964 22.059 46.7077 22.4597Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_103_48'>
        <rect width={50} height={50} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(Walletconnect1Icon);
export { Memo as Walletconnect1Icon };

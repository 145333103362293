import { memo, SVGProps } from 'react';
import React from 'react';

const LogosMetamaskIconIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 44 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_103_462)'>
      <path d='M42.3453 -2.86102e-06L24.0375 13.5465L27.442 5.56316L42.3453 -2.86102e-06Z' fill='#D2D2D2' />
      <path
        d='M1.69846 0.0159969L16.562 5.56416L19.7946 13.6522L1.69846 0.0159969ZM34.9766 28.8097L43.0685 28.9637L40.2405 38.5707L30.3666 35.8522L34.9766 28.8097ZM9.02313 28.8097L13.616 35.8522L3.7588 38.5708L0.947964 28.9637L9.02313 28.8097Z'
        fill='#D2D2D2'
      />
      <path
        d='M19.3561 11.5921L19.6868 22.2726L9.79163 21.8224L12.6063 17.5761L12.642 17.5352L19.3561 11.5921ZM24.5433 11.4729L31.36 17.5356L31.3953 17.5762L34.21 21.8226L24.317 22.2726L24.5433 11.4729ZM13.9068 28.8408L19.3098 33.0506L13.0335 36.0808L13.9068 28.8408ZM30.0961 28.8401L30.9513 36.0809L24.6925 33.0502L30.0961 28.8401Z'
        fill='#D2D2D2'
      />
      <path
        d='M24.8296 32.6537L31.1808 35.729L25.273 38.5367L25.3343 36.681L24.8296 32.6537ZM19.1685 32.655L18.6836 36.6507L18.7235 38.5345L12.8018 35.729L19.1685 32.655Z'
        fill='#D5BFB2'
      />
      <path
        d='M14.3376 28.805L13.4243 36.3117L8.5288 28.9692L14.3376 28.805ZM29.6626 28.8052L35.4716 28.9692L30.5578 36.312L29.6626 28.8052ZM34.352 21.3978L30.1245 25.7063L26.865 24.2168L25.3045 27.4975L24.2815 21.856L34.352 21.3978ZM9.6458 21.3978L19.7183 21.856L18.6951 27.4975L17.1343 24.2173L13.8921 25.7065L9.6458 21.3978Z'
        fill='#B6B6B6'
      />
      <path
        d='M9.36038 20.5139L14.1434 25.3674L14.309 30.1589L9.36038 20.5139ZM34.6432 20.5052L29.6855 30.1674L29.8722 25.3674L34.6432 20.5052ZM19.4839 20.8095L19.6764 22.0212L20.152 25.0397L19.6764 32.1406L18.4004 26.863L18.3999 26.786L19.4839 20.8095ZM24.5124 20.7927L25.5992 26.786L25.5987 26.863L24.0871 32.3038L24.0919 32.4617L23.8657 24.9847L24.5124 20.7927Z'
        fill='#DEDEDE'
      />
      <path
        d='M30.2972 25.174L30.1354 29.337L25.0897 33.2681L24.0697 32.5475L25.213 26.6583L30.2972 25.174ZM13.7187 25.174L18.7852 26.6583L19.9285 32.5475L18.9085 33.2681L13.8627 29.3366L13.7187 25.174Z'
        fill='white'
      />
      <path
        d='M11.8354 34.8094L18.2907 37.868L18.2634 36.5619L18.8035 36.0877H25.1925L25.7522 36.5602L25.7109 37.8654L32.1254 34.817L29.004 37.3964L25.2299 39.9885H18.7517L14.98 37.3857L11.8354 34.8094Z'
        fill='#C0AC9D'
      />
      <path
        d='M41.1356 0.374997L43.3333 6.96783L41.9608 13.634L42.9381 14.3878L41.6156 15.3968L42.6096 16.1645L41.2935 17.363L42.1015 17.9482L39.9571 20.4525L31.1621 17.8917L31.086 17.8508L24.7481 12.5045L41.1356 0.374997ZM2.8643 0.374997L19.252 12.5045L12.914 17.8508L12.8378 17.8917L4.0428 20.4525L1.89846 17.9482L2.7058 17.3635L1.39046 16.1645L2.38246 15.3977L1.04013 14.3858L2.0543 13.6315L0.666631 6.96816L2.8643 0.374997Z'
        fill='#B1B1B1'
      />
      <path
        d='M30.731 17.3317L40.0498 20.0449L43.0773 29.3759H35.09L29.5866 29.4452L33.589 21.6439L30.731 17.3317ZM13.267 17.3317L10.4085 21.6439L14.4113 29.4452L8.91046 29.3759H0.937464L3.94796 20.0451L13.267 17.3317ZM27.8963 5.51875L25.2898 12.5586L24.7366 22.0686L24.525 25.0494L24.5081 32.6641H19.4896L19.4733 25.0637L19.261 22.0661L18.7076 12.5586L16.1015 5.51875H27.8963Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_103_462'>
        <rect width={42.6667} height={40} fill='white' transform='translate(0.666631 -2.86102e-06)' />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(LogosMetamaskIconIcon);
export { Memo as LogosMetamaskIconIcon };

import { useEffect, useState } from 'react';
import './App.css';
import { Home } from './components/Home';
import { Landing } from './components/Landing';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [page, setPage] = useState(window.location.pathname === 'home' ? 'home' : 'landing');
  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname === 'home') {
      
    }
  }, []);
  return (
    <>
      {page === 'landing' && <Landing setPage={setPage} />}
      {page === 'home' && <Home />}
    </>
  );
}

export default App;

import './Landing.css';
import logo from '../img/logo.svg'
import assets from '../img/assets.svg'
import appImg from '../img/app.png';
import { Footer } from './Landing/Footer';
import { SignIn } from './Landing/SignIn';

export const Landing = ({ setPage }) => {
    return (
      <div className="landing">
        <div className="landing_inner">
          <header className="header">
            <div className="header__inner">
              <img src={logo} className="header__logo" alt="logo" />
              <div className="header__nav">
                <a href="#" className="header__nav_item">About us</a>
                <a href="#" className='header__nav_item'>Privacy policy</a>
              </div>
              <div class="button">
                <img class="wallet-icon" alt="" src={assets} />
                <div class="connect-wallet" onClick={() => setPage('home')}>Connect wallet</div>
              </div>
            </div>
          </header>
          <div className="content">
            <div className="content__info">
              <div className="content__title">
                Own your Gambling Reputation and Stats and let it work for you
              </div>
              <div className="steps">
                <div className="steps__line">
                  <div className="step active">
                    1
                  </div>
                  <div className="step_space"/>
                  <div className="step">
                    2
                  </div>
                  <div className="step_space"/>
                  <div className="step">
                    3
                  </div>
                  <div className="step_space"/>
                  <div className="step">
                    4
                  </div>
                </div>
                <div className="steps__names">
                  <div className="step_name">
                    Sign up
                  </div>
                  <div className="step_name">
                    <div className="step_name__inner">
                      Own your complete web3 gambling stats 
                    </div>
                  </div>
                  <div className="step_name">
                    Get the best casino offers
                  </div>
                  <div className="step_name">
                    Earn on the actions you take
                  </div>
                </div>
              </div>
            </div>
            <img src={appImg} alt="app" className="content__img" />
          </div>
          <SignIn setPage={setPage} />
          <Footer />
        </div>
      </div>
    );
  }
  
  
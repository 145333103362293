import { memo, SVGProps } from 'react';
import React from 'react';

const Frame1Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.5203 0.408093C19.2676 0.496451 17.8821 0.737837 16.4835 1.11139C11.2741 2.50279 6.74222 5.74473 3.76934 10.2068C1.7785 13.1948 0.533615 16.6021 0.1186 20.1987C-0.115438 22.2273 -0.0251848 22.8719 0.58851 23.5548C1.40618 24.4647 2.66363 24.3711 3.53813 23.3353C3.87395 22.9376 3.92837 22.6842 4.09609 20.7369C4.27319 18.6803 4.92843 16.404 5.90633 14.448C6.38687 13.4869 7.15294 12.2453 7.73316 11.4871C8.2399 10.8249 9.42764 9.51666 9.84443 9.16155C10.0839 8.95748 10.4913 8.61049 10.7497 8.39039C11.5008 7.75066 13.0915 6.72348 14.1299 6.20769C16.4188 5.07078 18.6298 4.49939 21.2799 4.35993C22.9157 4.27383 23.6145 3.96337 24.007 3.14818C24.1353 2.8819 24.1558 2.77568 24.158 2.36827C24.161 1.80489 24.0346 1.43238 23.7197 1.07659C23.1387 0.420095 22.4347 0.272988 20.5203 0.408093ZM27.7082 0.764879C26.7435 1.12429 26.0854 2.1795 26.2782 3.05768C26.4678 3.92098 27.2202 4.67548 28.0172 4.80151C28.6119 4.89558 29.3594 4.64566 29.8184 4.19931C29.958 4.06357 30.131 3.8149 30.2273 3.61162C30.3803 3.28853 30.3942 3.21553 30.3942 2.73511C30.3942 2.22635 30.3882 2.19988 30.19 1.82874C29.8173 1.13078 29.1869 0.723164 28.42 0.684068C28.076 0.666565 27.9266 0.683492 27.7082 0.764879Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4531 9.16551C13.7944 9.24957 13.1223 9.7576 12.7978 10.4167C12.647 10.7231 12.6306 10.8031 12.6306 11.2318C12.6306 11.6758 12.6435 11.7334 12.8278 12.1064C13.0555 12.5673 13.3894 12.9003 13.8515 13.1274C14.1204 13.2595 14.2035 13.2738 14.6996 13.2738C15.1882 13.2738 15.2834 13.258 15.5524 13.1319C15.9759 12.9334 16.4321 12.483 16.6184 12.0796C16.7527 11.7886 16.7685 11.6983 16.767 11.23C16.7656 10.7606 16.7495 10.673 16.6134 10.3915C16.4211 9.99359 15.9444 9.51077 15.5794 9.34401C15.2359 9.1871 14.8111 9.11976 14.4531 9.16551ZM10.4044 13.4507C9.6802 13.6893 9.11449 14.2974 8.32526 15.6857C7.05906 17.9132 6.26973 20.4337 5.94591 23.2835C5.90987 23.6006 5.88149 24.4378 5.8828 25.144C5.8874 27.6282 6.2988 29.7351 7.2745 32.2713C7.59183 33.0961 8.42369 34.7051 8.93608 35.4851C9.44334 36.2573 10.455 37.5469 11.091 38.232C11.9588 39.1669 13.8428 40.7349 14.6077 41.1589C14.8575 41.2974 14.929 41.3115 15.3806 41.3115C15.8297 41.3115 15.912 41.2955 16.2251 41.147C16.6729 40.9347 16.9765 40.6569 17.1586 40.2927C17.4731 39.6634 17.4943 39.1686 17.2307 38.6068C17.0183 38.1543 16.8747 37.9964 16.1541 37.4237C14.338 35.9801 13.4522 35.0481 12.392 33.4649C10.6091 30.8028 9.71357 27.403 9.92739 24.1089C10.0868 21.6526 10.9208 19.016 12.1601 17.0504C12.674 16.2353 12.8139 15.8567 12.8139 15.2805C12.8139 14.5753 12.1998 13.8037 11.4258 13.5366C11.1237 13.4323 10.5951 13.3879 10.4044 13.4507Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.9332 26.219C47.6407 26.2621 47.1371 26.4723 46.9009 26.65C46.5796 26.8918 46.282 27.3382 46.1795 27.732C46.1352 27.902 46.0623 28.395 46.0174 28.8275C45.9726 29.26 45.8788 29.9523 45.8091 30.3658C45.1902 34.0357 43.4829 37.4404 40.947 40.062C39.8664 41.1791 38.0717 42.5746 36.8808 43.2237C34.9137 44.2959 33.5349 44.811 31.4503 45.2522C30.2693 45.5022 29.6149 45.5752 28.1332 45.6223C27.17 45.6528 26.6711 45.6898 26.5031 45.7429C25.7976 45.9662 25.1754 46.7271 25.1102 47.4462C25.0327 48.3007 25.6415 49.2008 26.474 49.4627C26.8241 49.5728 26.9398 49.5807 28.1594 49.5769C28.8797 49.5746 29.7282 49.5438 30.0451 49.5084C32.7013 49.2115 35.0963 48.535 37.4832 47.4074C39.2535 46.5711 41.4179 45.0964 42.9776 43.6639C44.1426 42.5939 45.4247 41.0744 46.4001 39.6079C47.0026 38.7019 47.9874 36.8488 48.4231 35.8014C49.3344 33.6105 49.9941 30.6045 49.9996 28.6182C50.0021 27.7311 49.9949 27.6602 49.8795 27.4277C49.7262 27.1192 49.4042 26.7494 49.116 26.5511C48.7941 26.3295 48.2399 26.1739 47.9332 26.219ZM20.9308 44.9121C19.9085 45.1659 19.217 46.0821 19.2954 47.0788C19.3463 47.726 19.8155 48.3721 20.4903 48.7242C20.8057 48.8888 20.8579 48.8995 21.3499 48.8996C21.8463 48.8997 21.8909 48.8903 22.2018 48.7217C22.844 48.3732 23.2383 47.8539 23.3741 47.1777C23.4233 46.9321 23.4188 46.8203 23.3477 46.5321C23.1358 45.6732 22.4871 45.0483 21.644 44.8908C21.3054 44.8276 21.2663 44.8288 20.9308 44.9121Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.8517 11.89C23.363 12.0466 22.9087 12.4483 22.7088 12.9004C22.485 13.4064 22.6725 14.0962 23.1706 14.6C23.5819 15.016 23.8017 15.084 24.9877 15.1623C26.3806 15.2542 27.278 15.4662 28.3013 15.9449C29.8769 16.682 31.1588 17.8245 32.1769 19.3991C32.8282 20.4064 33.2898 21.895 33.4627 23.5458C33.5027 23.9273 33.5619 24.1893 33.6561 24.4C33.8938 24.9323 34.5023 25.3706 35.0741 25.4213C35.3154 25.4426 35.4021 25.4209 35.7379 25.2549C36.4061 24.9244 36.8132 24.3286 36.8132 23.6809C36.8132 23.1873 36.6042 21.8128 36.4144 21.059C36.2299 20.3257 35.7097 18.961 35.3919 18.3762C35.0867 17.8146 34.455 16.8465 34.107 16.407C33.0157 15.0286 31.4054 13.77 29.7405 12.9942C28.6181 12.4713 27.097 12.0241 26.0751 11.9169C25.0692 11.8113 24.1327 11.8 23.8517 11.89ZM24.3728 20.7378C22.7175 21.0614 21.4794 22.2248 21.0694 23.8421C20.9241 24.4152 20.9095 24.8355 21.0156 25.3885C21.2302 26.5068 21.8688 27.4745 22.806 28.1016C23.4952 28.5627 24.3913 28.817 25.1585 28.769C26.6868 28.6734 28.0584 27.7479 28.6839 26.3902C29.4277 24.7758 29.0572 23.0054 27.7171 21.77C26.8189 20.9419 25.4654 20.5242 24.3728 20.7378ZM14.6208 24.0206C14.3094 24.081 14.1003 24.2018 13.7763 24.5084C13.2684 24.989 13.186 25.3019 13.2898 26.3561C13.3936 27.411 13.6578 28.6493 13.9558 29.4776C14.2954 30.422 15.0749 31.8666 15.6901 32.6919C16.7258 34.0814 18.3532 35.4496 19.901 36.2318C20.4251 36.4967 21.3687 36.8909 21.8202 37.0336C22.889 37.3714 24.6824 37.6286 25.6248 37.5793C26.391 37.5392 26.9327 37.2268 27.2534 36.6401C27.3832 36.4026 27.4064 36.3026 27.4038 35.9924C27.3999 35.5276 27.2682 35.2198 26.9117 34.8427C26.5072 34.4148 26.3166 34.3536 25.1028 34.2621C23.4746 34.1392 22.6827 33.9373 21.4918 33.3413C19.4297 32.3092 17.7708 30.3955 17.0438 28.21C16.8687 27.6833 16.6716 26.7044 16.5471 25.7423C16.4635 25.096 16.3615 24.8405 16.0657 24.5367C15.6624 24.1224 15.1109 23.9255 14.6208 24.0206Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.0887 11.885C37.3862 12.0329 36.7671 12.6492 36.562 13.4048C36.4116 13.9592 36.5794 14.526 37.1038 15.2339C37.8092 16.1864 38.586 17.4681 39.0349 18.4202C39.4117 19.2194 39.8816 20.6858 40.0725 21.658C40.5137 23.9053 40.4665 26.3162 39.9408 28.3922C39.5833 29.8038 38.9018 31.4926 38.3017 32.4538C37.2795 34.0911 36.7634 34.7226 35.3757 36.0338C34.9392 36.4463 34.5199 36.8736 34.4438 36.9834C34.2118 37.3184 34.1009 37.6822 34.1009 38.1082C34.1009 38.4543 34.1227 38.5383 34.3106 38.9137C34.5471 39.3866 34.7848 39.6149 35.314 39.8776C35.76 40.099 36.1594 40.1292 36.6123 39.9757C37.0771 39.8182 37.3845 39.6012 38.1679 38.8778C40.8949 36.3596 42.741 33.3319 43.7303 29.7548C43.9174 29.0785 44.1535 27.8246 44.2626 26.9278C44.3817 25.9497 44.4078 23.8783 44.313 22.9344C44.1342 21.1547 43.7243 19.4698 43.0525 17.7537C42.5488 16.4671 42.0081 15.3986 41.3815 14.452C40.3284 12.8611 39.806 12.2583 39.2657 12.0108C38.9457 11.8642 38.4427 11.8105 38.0887 11.885ZM30.6063 39.2086C30.0607 39.3867 29.5398 39.8515 29.2913 40.382C29.1698 40.6412 29.1509 40.7489 29.1509 41.1806C29.1509 41.638 29.1651 41.7085 29.3217 42.0268C29.5428 42.476 30.0424 42.9543 30.47 43.1259C30.7049 43.2202 30.8766 43.2483 31.2199 43.2489C31.6133 43.2495 31.7065 43.23 32.02 43.0814C32.9267 42.6517 33.4579 41.6608 33.2392 40.8068C33.0756 40.1678 32.6346 39.6125 32.0702 39.3344C31.7819 39.1924 31.6629 39.165 31.279 39.152C30.9969 39.1424 30.7441 39.1637 30.6063 39.2086Z'
      fill='white'
    />
  </svg>
);
const Memo = memo(Frame1Icon);
export { Memo as Frame1Icon };

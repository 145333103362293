import { FP } from './FP/FP';
import './Home.css';
import { Footer } from './Landing/Footer';
import { SignIn } from './Landing/SignIn';

export const Home = () => {
    return (
      <FP />
    );
  }
  
  
import React, { memo, useState } from 'react';

import resets from '../_resets.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { AssetsIcon } from './AssetsIcon';
import classes from './FP.module.css';
import { Frame1Icon } from './Frame1Icon';
import { Frame1Icon2 } from './Frame1Icon2';
import { Frame2Icon } from './Frame2Icon';
import { Frame277Icon } from './Frame277Icon';
import { GroupIcon } from './GroupIcon';
import { GroupIcon2 } from './GroupIcon2';
import { GroupIcon3 } from './GroupIcon3';
import { GroupIcon4 } from './GroupIcon4';
import { LogosMetamaskIconIcon } from './LogosMetamaskIconIcon';
import { RemixIconsLineSystemArrowDownS } from './RemixIconsLineSystemArrowDownS/RemixIconsLineSystemArrowDownS';
import { StatsIcon } from './StatsIcon';
import { UilLinkHIcon } from './UilLinkHIcon';
import { UilTrophyIcon } from './UilTrophyIcon';
import { UserIcon } from './UserIcon';
import { Walletconnect1Icon } from './Walletconnect1Icon';
import { Walletconnect1Icon2 } from './Walletconnect1Icon2';

export const FP = memo(function FP(props = {}) {
  const [selected, setSelected] = useState('Games');
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.content}>
        <div className={classes.frame276}>
          <div className={classes.activeTab}>
            <div className={classes.gambling}>Gambling</div>
            <div className={classes.rectangle15}></div>
          </div>
          <div className={classes.tab}>
            <div className={classes.betting}>Betting</div>
            <div className={classes.rectangle152}></div>
          </div>
          <div className={classes.tab2}>
            <div className={classes.community}>Community</div>
            <div className={classes.rectangle153}></div>
          </div>
        </div>
        <div className={classes.frame294}>
          <div className={classes.frame287}>
            <div className={classes.showBy}>Show by: </div>
            <div className={classes.frame28}>
              <div className={classes.frame27}>
              <Dropdown className={classes.dropdown}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selected}
                <RemixIconsLineSystemArrowDownS
                  className={classes.remixIconsLineSystemArrowDownS}
                  classes={{ group: classes.group }}
                  swap={{
                    group: (
                      <div className={classes.group}>
                        <GroupIcon className={classes.icon} />
                      </div>
                    ),
                  }}
                />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelected('Games')}>Games</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelected('Casionos')}>Casionos</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelected('Titles')}>Titles</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            </div>
          </div>
          <div className={classes.frame293}>
            <div className={classes.progress}>
              <div className={classes.frame277}>
                <Frame277Icon className={classes.icon5} />
              </div>
              <div className={classes.yourLevelIntermediate}>
                <p className={classes.labelWrapper}>
                  <span className={classes.label}>Your level: </span>
                  <span className={classes.label2}>Intermediate</span>
                </p>
              </div>
            </div>
            <div className={classes.slots}>
              <div className={classes.frame288}>
                <div className={classes.slots2}>Slots</div>
              </div>
              <div className={classes.frame291}>
                <div className={classes.frame289}>
                  <div className={classes.slot}>
                    <div className={classes.frame278}>
                      <div className={classes.slotsRegularCustomer}>Slots - Regular Customer</div>
                      <div className={classes.playedOver10DifferentGamesSpen}>
                        <ul className={classes.list}>
                          <li>
                            <div className={classes.textBlock}>Played over 10 different games</div>
                          </li>
                          <li>
                            <div className={classes.textBlock2}>Spent over 20 hours playing in our system</div>
                          </li>
                          <li>
                            <div className={classes.textBlock3}>Played at 5 different casinos within our system</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={classes.slot2}>
                    <div className={classes.slotsLuckyStreak}>Slots - Lucky Streak</div>
                    <div className={classes.wonAtLeast10GamesInARowEarnedM}>
                      <ul className={classes.list2}>
                        <li>
                          <div className={classes.textBlock4}>Won at least 10 games in a row</div>
                        </li>
                        <li>
                          <div className={classes.textBlock5}>Earned more than 500 credits in a single game</div>
                        </li>
                        <li>
                          <div className={classes.textBlock6}>Got a 3x multiplier in a slot machine game</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={classes.frame290}>
                  <div className={classes.slot3}>
                    <div className={classes.slotsHighRoller}>Slots - High Roller</div>
                    <div className={classes.placedABetWorthMoreThan1000Cre}>
                      <ul className={classes.list3}>
                        <li>
                          <div className={classes.textBlock7}>Placed a bet worth more than 1000 credits</div>
                        </li>
                        <li>
                          <div className={classes.textBlock8}>Won a jackpot worth more than 5000 credits</div>
                        </li>
                        <li>
                          <div className={classes.textBlock9}>Played at our VIP lounge for over 5 hours</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.slot4}>
                    <div className={classes.slotsExplorer}>Slots - Explorer</div>
                    <div className={classes.playedEveryGameInOurSystemAtLe}>
                      <ul className={classes.list4}>
                        <li>
                          <div className={classes.textBlock10}>Played every game in our system at least once</div>
                        </li>
                        <li>
                          <div className={classes.textBlock11}>Visited every casino within our system</div>
                        </li>
                        <li>
                          <div className={classes.textBlock12}>Found all hidden Easter eggs within our system</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.otherBlocks}>
              <div className={classes.frame282}>
                <div className={classes.roulettes}>Roulettes</div>
                <RemixIconsLineSystemArrowDownS
                  className={classes.remixIconsLineSystemArrowDownS2}
                  classes={{ group: classes.group2 }}
                  swap={{
                    group: (
                      <div className={classes.group2}>
                        <GroupIcon2 className={classes.icon2} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.frame283}>
                <div className={classes.poker}>Poker</div>
                <RemixIconsLineSystemArrowDownS
                  className={classes.remixIconsLineSystemArrowDownS3}
                  classes={{ group: classes.group3 }}
                  swap={{
                    group: (
                      <div className={classes.group3}>
                        <GroupIcon3 className={classes.icon3} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.frame284}>
                <div className={classes.blackjack}>Blackjack</div>
                <RemixIconsLineSystemArrowDownS
                  className={classes.remixIconsLineSystemArrowDownS4}
                  classes={{ group: classes.group4 }}
                  swap={{
                    group: (
                      <div className={classes.group4}>
                        <GroupIcon4 className={classes.icon4} />
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.fairApp}>
        <div className={classes.connectFairApp}>Connect FAIR Play</div>
        <div className={classes.frame1}>
          <Frame1Icon className={classes.icon6} />
        </div>
      </div>
      <div className={classes.walletConnect}>
        <div className={classes.connectWalletConnect}>
          <div className={classes.textBlock13}>Connect </div>
          <div className={classes.textBlock14}>Wallet Connect</div>
        </div>
        <div className={classes.walletConnect1}>
          <Walletconnect1Icon className={classes.icon7} />
        </div>
      </div>
      <div className={classes.phantomWallet}>
        <div className={classes.connectPhantomWallet}>Connect Phantom Wallet</div>
        <div className={classes.walletConnect12}>
          <Walletconnect1Icon2 className={classes.icon8} />
        </div>
      </div>
      <div className={classes.metamask}>
        <div className={classes.connectMetamask}>Connect Metamask</div>
        <div className={classes.walletConnect13}>
          <div className={classes.frame265}>
            <div className={classes.logosMetamaskIcon}>
              <LogosMetamaskIconIcon className={classes.icon9} />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.fAIR2}>
        <div className={classes.frame2}>
          <Frame2Icon className={classes.icon10} />
        </div>
        <div className={classes.frame12}>
          <Frame1Icon2 className={classes.icon11} />
        </div>
      </div>
      <div className={classes.rectangle1}></div>
      <div className={classes.menu}>
        <a href="#" className={classes.frame272}>
          <div className={classes.uilTrophy}>
            <UilTrophyIcon className={classes.icon12} />
          </div>
          <div className={classes.achievements}>Achievements</div>
        </a>
        <a href="#" className={classes.frame270}>
          <div className={classes.assets}>
            <AssetsIcon className={classes.icon13} />
          </div>
          <div className={classes.assets2}>Assets</div>
        </a>
        <a href="#" className={classes.frame269}>
          <div className={classes.stats}>
            <StatsIcon className={classes.icon14} />
          </div>
          <div className={classes.stats2}>Stats</div>
        </a>
        <a href="#" className={classes.frame271}>
          <div className={classes.user}>
            <UserIcon className={classes.icon15} />
          </div>
          <div className={classes.profile}>Profile</div>
        </a>
      </div>
      <div className={classes.yourActivity}>Your activity</div>
      <button className={classes.button}>
        <div className={classes.uilLinkH}>
          <UilLinkHIcon className={classes.icon16} />
        </div>
        <div className={classes.shareYourProfile}>Share your profile</div>
      </button>
    </div>
  );
});

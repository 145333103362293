import { memo, SVGProps } from 'react';
import React from 'react';

const Walletconnect1Icon2 = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M46.008 24.7717H40.67C40.67 13.8499 31.8552 5 20.9685 5C10.1871 5 1.40746 13.604 1.19675 24.3854C0.986042 35.4126 11.3811 45 22.4435 45H23.8482C33.6112 45 46.7104 37.3793 48.7472 28.108C49.0633 26.5979 48.08 25.158 46.5699 24.842C46.3943 24.7717 46.2187 24.7717 46.008 24.7717ZM12.9966 25.2634C12.9966 26.7384 11.8025 27.8973 10.3276 27.8973C8.85259 27.8973 7.69368 26.7033 7.69368 25.2634V20.9789C7.69368 19.504 8.88771 18.345 10.3627 18.345C11.8377 18.345 12.9966 19.5391 12.9966 20.9789V25.2634ZM22.1976 25.2634C22.1976 26.7384 21.0036 27.8973 19.5286 27.8973C18.0536 27.8973 16.8947 26.7033 16.8947 25.2634V20.9789C16.8947 19.504 18.0888 18.345 19.5637 18.345C21.0387 18.345 22.1976 19.5391 22.1976 20.9789V25.2634Z'
      fill='url(#paint0_linear_103_460)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_103_460'
        x1={24.9979}
        y1={5.09131}
        x2={24.9979}
        y2={44.9965}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset={1} stopColor='white' stopOpacity={0.82} />
      </linearGradient>
    </defs>
  </svg>
);
const Memo = memo(Walletconnect1Icon2);
export { Memo as Walletconnect1Icon2 };

import './SignIn.css';
import fairApp from '../../img/frame-1.svg';
import walletConnect from '../../img/walletconnect-1.svg';
import phantom from '../../img/walletconnect-11.svg';
import metamask from '../../img/walletconnect-12.svg';

export const SignIn = ({ setPage }) => {
    return (
      <div className="sign_in">
        <div className="sign_in__title">
            Sign in
            <br />
            or Sign up
        </div>
        <div onClick={() => setPage('home')} className="sign_in__wallet">
            Connect<br />FAIR Play
            <img class="fair-app-child" alt="" src={fairApp} />
        </div>
        <div onClick={() => setPage('home')} className="sign_in__wallet wallet_connect">
            Connect<br/>
            Wallet Connect
            <img className="sign_in__wallet_icon" alt="" src={walletConnect} />
        </div>
        <div onClick={() => setPage('home')} className="sign_in__wallet phantom">
            Connect<br />Phantom Wallet
            <img className="sign_in__wallet_icon" alt="" src={phantom} />
        </div>
        <div onClick={() => setPage('home')} className="sign_in__wallet metamask">
            Connect<br />Metamask
            <img className="sign_in__wallet_icon" alt="" src={metamask} />
        </div>
      </div>
    );
  }
  
  
import { memo, SVGProps } from 'react';
import React from 'react';

const GroupIcon3 = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.5223 12.6476L16.2752 7.89466L17.6329 9.25236L11.5223 15.363L5.41161 9.25236L6.76932 7.89466L11.5223 12.6476Z'
      fill='#525252'
    />
  </svg>
);
const Memo = memo(GroupIcon3);
export { Memo as GroupIcon3 };

import { memo, SVGProps } from 'react';
import React from 'react';

const Frame1Icon2 = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.8099 0.333976C15.7836 0.406357 14.6487 0.604097 13.503 0.910107C9.2355 2.04992 5.5231 4.70565 3.08777 8.36085C1.45691 10.8086 0.437127 13.5998 0.0971552 16.5461C-0.0945644 18.2079 -0.0206309 18.7359 0.482096 19.2953C1.15191 20.0407 2.182 19.964 2.89837 19.1155C3.17346 18.7897 3.21805 18.5822 3.35544 16.9869C3.50052 15.3022 4.03728 13.4375 4.83835 11.8352C5.232 11.0479 5.85955 10.0308 6.33486 9.40965C6.74997 8.86722 7.72294 7.79554 8.06437 7.50465C8.26056 7.33747 8.59426 7.05323 8.80593 6.87292C9.42126 6.34887 10.7243 5.50742 11.5749 5.0849C13.45 4.15356 15.2612 3.68549 17.4321 3.57125C18.7721 3.50072 19.3445 3.24639 19.6661 2.57861C19.7712 2.36048 19.788 2.27346 19.7898 1.93971C19.7923 1.47821 19.6887 1.17306 19.4307 0.881601C18.9548 0.343808 18.3781 0.223301 16.8099 0.333976ZM22.698 0.626249C21.9077 0.920667 21.3687 1.78508 21.5266 2.50447C21.682 3.21166 22.2983 3.82974 22.9512 3.93298C23.4383 4.01004 24.0507 3.80531 24.4267 3.43967C24.5411 3.32848 24.6827 3.12477 24.7616 2.95824C24.8869 2.69358 24.8983 2.63377 24.8983 2.24023C24.8983 1.82346 24.8934 1.80178 24.7311 1.49774C24.4258 0.92599 23.9094 0.592076 23.2811 0.56005C22.9994 0.545711 22.8769 0.559578 22.698 0.626249Z'
      fill='#2E74E9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8397 7.50789C11.3002 7.57675 10.7496 7.99292 10.4838 8.53286C10.3602 8.78383 10.3468 8.84939 10.3468 9.20056C10.3468 9.56427 10.3574 9.61149 10.5084 9.91703C10.6949 10.2946 10.9684 10.5674 11.3469 10.7534C11.5672 10.8616 11.6353 10.8734 12.0417 10.8734C12.4419 10.8734 12.52 10.8603 12.7403 10.7571C13.0873 10.5944 13.461 10.2255 13.6135 9.89505C13.7236 9.6567 13.7365 9.58273 13.7353 9.19906C13.7341 8.81457 13.721 8.74283 13.6095 8.51217C13.4519 8.18624 13.0615 7.79072 12.7624 7.65411C12.481 7.52558 12.1331 7.47041 11.8397 7.50789ZM8.52314 11.0182C7.92992 11.2137 7.4665 11.7119 6.81998 12.8491C5.78273 14.6738 5.13612 16.7385 4.87086 19.0731C4.84134 19.3328 4.81808 20.0187 4.81915 20.5972C4.82293 22.6322 5.15994 24.3581 5.95922 26.4357C6.21916 27.1114 6.90061 28.4294 7.32035 29.0684C7.73588 29.7009 8.56464 30.7574 9.08564 31.3186C9.79653 32.0845 11.3398 33.3689 11.9664 33.7163C12.1711 33.8297 12.2296 33.8413 12.5995 33.8413C12.9675 33.8413 13.0349 33.8281 13.2914 33.7066C13.6582 33.5326 13.9069 33.305 14.0561 33.0067C14.3137 32.4912 14.3311 32.0859 14.1151 31.6257C13.9412 31.255 13.8235 31.1256 13.2332 30.6565C11.7455 29.4739 11.0199 28.7104 10.1514 27.4135C8.69088 25.2327 7.95725 22.4477 8.13241 19.7493C8.26303 17.7371 8.94623 15.5772 9.96138 13.9671C10.3824 13.2993 10.497 12.9892 10.497 12.5172C10.497 11.9395 9.99391 11.3074 9.35988 11.0886C9.11238 11.0032 8.67942 10.9668 8.52314 11.0182Z'
      fill='#064580'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.2659 21.4778C39.0263 21.5131 38.6138 21.6853 38.4203 21.8309C38.1571 22.029 37.9133 22.3946 37.8293 22.7172C37.793 22.8565 37.7333 23.2603 37.6966 23.6147C37.6598 23.969 37.583 24.536 37.5259 24.8748C37.0189 27.8811 35.6204 30.6701 33.543 32.8177C32.6578 33.7329 31.1876 34.876 30.212 35.4077C28.6006 36.2861 27.4711 36.708 25.7635 37.0695C24.796 37.2742 24.2599 37.3341 23.0462 37.3726C22.2571 37.3976 21.8484 37.4279 21.7108 37.4714C21.1329 37.6543 20.6232 38.2776 20.5698 38.8667C20.5063 39.5667 21.005 40.304 21.687 40.5186C21.9737 40.6088 22.0686 40.6152 23.0676 40.6121C23.6576 40.6103 24.3528 40.585 24.6124 40.556C26.7882 40.3128 28.7502 39.7586 30.7055 38.8349C32.1557 38.1498 33.9287 36.9418 35.2064 35.7683C36.1607 34.8918 37.2111 33.647 38.01 32.4457C38.5036 31.7035 39.3104 30.1855 39.6673 29.3275C40.4138 27.5328 40.9542 25.0703 40.9587 23.4432C40.9607 22.7165 40.9549 22.6584 40.8603 22.468C40.7347 22.2152 40.4709 21.9123 40.2349 21.7498C39.9712 21.5683 39.5172 21.4409 39.2659 21.4778ZM17.1461 36.7908C16.3086 36.9988 15.7422 37.7492 15.8064 38.5657C15.8481 39.0959 16.2324 39.6252 16.7852 39.9136C17.0436 40.0485 17.0864 40.0572 17.4894 40.0573C17.896 40.0574 17.9326 40.0497 18.1872 39.9116C18.7133 39.6261 19.0364 39.2007 19.1475 38.6467C19.1879 38.4456 19.1842 38.354 19.126 38.1179C18.9524 37.4143 18.421 36.9024 17.7303 36.7734C17.453 36.7216 17.4209 36.7226 17.1461 36.7908Z'
      fill='#064580'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.5388 9.73976C19.1385 9.86804 18.7664 10.1971 18.6026 10.5674C18.4193 10.9819 18.5729 11.547 18.9809 11.9597C19.3178 12.3005 19.4979 12.3562 20.4695 12.4204C21.6105 12.4957 22.3456 12.6693 23.1839 13.0614C24.4746 13.6653 25.5247 14.6011 26.3587 15.891C26.8923 16.7163 27.2704 17.9356 27.412 19.2879C27.4447 19.6005 27.4933 19.8151 27.5704 19.9877C27.7651 20.4237 28.2637 20.7828 28.7321 20.8243C28.9297 20.8418 29.0008 20.824 29.2758 20.688C29.8232 20.4173 30.1567 19.9292 30.1567 19.3986C30.1567 18.9943 29.9854 17.8683 29.83 17.2508C29.6788 16.6501 29.2527 15.5321 28.9924 15.0531C28.7424 14.5931 28.2249 13.8 27.9398 13.44C27.0458 12.3108 25.7267 11.2798 24.3629 10.6443C23.4434 10.2159 22.1973 9.84962 21.3603 9.76174C20.5362 9.67528 19.7691 9.66601 19.5388 9.73976ZM19.9657 16.9877C18.6097 17.2528 17.5956 18.2058 17.2597 19.5307C17.1406 20.0001 17.1287 20.3445 17.2156 20.7975C17.3914 21.7135 17.9145 22.5062 18.6823 23.02C19.2468 23.3977 19.9809 23.606 20.6093 23.5667C21.8614 23.4884 22.9849 22.7302 23.4973 21.618C24.1066 20.2955 23.8031 18.8453 22.7053 17.8332C21.9695 17.1549 20.8608 16.8127 19.9657 16.9877ZM11.9771 19.6769C11.722 19.7264 11.5507 19.8253 11.2853 20.0765C10.8692 20.4702 10.8018 20.7265 10.8868 21.5901C10.9718 22.4543 11.1882 23.4686 11.4323 24.1472C11.7106 24.9208 12.3491 26.1042 12.853 26.7803C13.7014 27.9185 15.0346 29.0393 16.3026 29.6801C16.7319 29.8971 17.5049 30.22 17.8747 30.3369C18.7503 30.6136 20.2194 30.8243 20.9913 30.7839C21.619 30.7511 22.0628 30.4952 22.3255 30.0145C22.4318 29.82 22.4508 29.7381 22.4487 29.484C22.4455 29.1032 22.3376 28.8511 22.0455 28.5422C21.7142 28.1917 21.5581 28.1415 20.5638 28.0665C19.2299 27.9659 18.5812 27.8005 17.6057 27.3122C15.9165 26.4668 14.5575 24.8991 13.962 23.1088C13.8185 22.6773 13.6571 21.8754 13.5551 21.0873C13.4866 20.5579 13.403 20.3485 13.1607 20.0997C12.8303 19.7603 12.3786 19.599 11.9771 19.6769Z'
      fill='#C22F8A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.2016 9.73563C30.626 9.85678 30.1189 10.3616 29.9509 10.9806C29.8277 11.4348 29.9652 11.8991 30.3947 12.479C30.9726 13.2593 31.6089 14.3092 31.9766 15.0891C32.2853 15.7439 32.6703 16.9451 32.8266 17.7415C33.188 19.5825 33.1494 21.5574 32.7188 23.2581C32.4259 24.4144 31.8676 25.7978 31.376 26.5852C30.5387 27.9264 30.1159 28.4437 28.9791 29.5179C28.6215 29.8558 28.278 30.2058 28.2157 30.2958C28.0257 30.5702 27.9348 30.8682 27.9348 31.2172C27.9348 31.5008 27.9527 31.5695 28.1066 31.8771C28.3003 32.2644 28.4951 32.4515 28.9286 32.6667C29.2939 32.848 29.6211 32.8727 29.9921 32.747C30.3728 32.618 30.6247 32.4402 31.2664 31.8477C33.5004 29.7848 35.0126 27.3045 35.823 24.3743C35.9763 23.8202 36.1697 22.793 36.2591 22.0585C36.3567 21.2571 36.378 19.5603 36.3004 18.7871C36.1539 17.3292 35.8181 15.949 35.2678 14.5431C34.8552 13.4892 34.4122 12.614 33.8989 11.8385C33.0363 10.5352 32.6083 10.0414 32.1657 9.83866C31.9036 9.71863 31.4915 9.67458 31.2016 9.73563ZM25.0721 32.1186C24.6251 32.2645 24.1984 32.6452 23.9948 33.0798C23.8953 33.2922 23.8798 33.3804 23.8798 33.734C23.8798 34.1087 23.8915 34.1665 24.0198 34.4272C24.2009 34.7952 24.6102 35.187 24.9604 35.3276C25.1529 35.4048 25.2935 35.4279 25.5748 35.4284C25.897 35.4288 25.9734 35.4129 26.2302 35.2911C26.9729 34.9392 27.408 34.1274 27.2289 33.4278C27.0949 32.9044 26.7337 32.4495 26.2713 32.2217C26.0351 32.1054 25.9376 32.0829 25.6231 32.0722C25.3921 32.0644 25.185 32.0819 25.0721 32.1186Z'
      fill='#2E74E9'
    />
  </svg>
);
const Memo = memo(Frame1Icon2);
export { Memo as Frame1Icon2 };
